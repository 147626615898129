<template>
    <b-row>
        <Left>
            <Title heading="Loading your jewelry information">
                <div class="loading-spinner-container">
                    <img class="loading-spinner" alt="spinner" :src="require('@/assets/spinner-black.png')" />
                </div>
            </Title>
        </Left>
        <Right>
            <Coverage />
        </Right>
    </b-row>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import jewelryTypesArray, { TYPES } from '@/constants/jewelryItems';
import Title from '@/components/Common/Title';
import Left from '@/components/Common/Left';
import Right from '@/components/Common/Right';
import Coverage from '@/components/Coverage';
import classes from '@/classes';

const JewelryTypes = {
    ring: TYPES.RING_TYPE,
    earrings: TYPES.EARRINGS_TYPE,
    bracelet: TYPES.BRACELET_TYPE,
    necklace: TYPES.NECKLACE_TYPE,
    watch: TYPES.WATCH_TYPE,
    pendant: TYPES.PENDANT_TYPE,
    chain: TYPES.CHAIN_TYPE,
    brooch: TYPES.BROOCH_TYPE,
    loose_stone: TYPES.LOOSE_STONE_TYPE
};

export default {
    name: 'Appraisal',
    components: { Coverage, Right, Left, Title },
    methods: {
        ...mapActions(['setPreviewQuoteLocation', 'setJewelryItems'])
    },
    async mounted() {
        if (this.$route.params && this.$route.params.appraisalId) {
            const { appraisalId } = this.$route.params;
            try {
                const { data } = await axios.get(`/api/appraisal/${appraisalId}`);
                const { customer, items } = data;

                const address = {};
                const country = ['CA', 'CAN'].includes(customer.countryCode) ? 'CA' : 'US';
                if (customer.postalCode && customer.state && customer.city) {
                    // Try to find the county for the appraisal
                    const addressToVerify = [
                        {
                            address1: customer.addressLine1,
                            address2: customer.addressLine2,
                            city: customer.city,
                            country,
                            state: customer.state,
                            zip: customer.postalCode
                        }
                    ];

                    const { data: results } = await axios
                        .post('/api/address/verifyAddresses', addressToVerify)
                        .catch(() => ({ data: {} }));

                    if (results && results.length) {
                        const addressData = results[0];
                        if (addressData.county && addressData.state && addressData.city && addressData.zip) {
                            address.county = addressData.county;
                            address.state = addressData.state;
                            address.city = addressData.city;
                            address.zipCode = addressData.zip;
                        }
                    }
                }

                if (!address.county && customer.postalCode) {
                    // The full address validation failed -- try to match using the zip code
                    const { data: addressData } = await axios(`/api/address/zip/${customer.postalCode}`).catch(() => ({
                        data: {}
                    }));

                    if (addressData && addressData.counties && addressData.counties.length === 1) {
                        address.county = addressData.counties[0];
                    }
                    if (addressData && addressData.cities && addressData.cities.length) {
                        // If there are multiple cities in this zip code, try not to override the one given to us
                        if (!customer.city || !addressData.cities.includes(customer.city)) {
                            address.city = addressData.cities[0];
                        }
                    }
                    address.state = addressData.state;
                    address.country = addressData.country;
                }

                this.$store.commit('setApplicantAndWearers', {
                    applicant: {
                        firstName: customer.firstName || null,
                        lastName: customer.lastName || null,
                        address: customer.addressLine1 || null,
                        addressSuite: customer.addressLine2 || null,
                        city: address.city || customer.city || null,
                        state: address.state || customer.state || null,
                        county: address.county || null,
                        country: address.country || country || null,
                        zipCode: address.zipCode || customer.postalCode || null,
                        phoneNumber: customer.phoneNumber || null,
                        emailAddress: customer.emailAddress || null,
                        isMailingAddress: true
                    },
                    wearerJewelryItems: [],
                    wearerCrime: classes.wearerCrime,
                    agreedTerms: false
                });
                this.$store.commit('setAppraisal', data);

                let streetName = customer.addressLine1.trim();
                if (customer.addressLine2) {
                    streetName = streetName + ', ' + customer.addressLine2;
                }
                this.setPreviewQuoteLocation({
                    zipCode: address.zipCode || customer.postalCode || null,
                    city: address.city || customer.city || null,
                    county: address.county || null,
                    state: address.state || customer.state || null,
                    country: address.country || country || null,
                    streetName: streetName.trim() || null
                });

                const jewelryItems = items.map(jewelry => {
                    const type = JewelryTypes[jewelry.jewelryType] || TYPES.OTHER_TYPE;
                    const name = jewelryTypesArray.find(t => t.value === type).text;
                    const totalValue = jewelry.totalValue;

                    return {
                        ...cloneDeep(classes.jewelryItem),
                        type,
                        name,
                        value: totalValue,
                        hasAppraisal: true,
                        appraisalId
                    };
                });
                this.setJewelryItems(jewelryItems);

                if (address.county) {
                    this.$router.push('/results');
                    return;
                }
            } catch (e) {
                /* Fall through - will redirect to main page */
            }
        }

        this.$router.push('/');
    }
};
</script>

<style scoped>
.loading-spinner-container {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}
.loading-spinner {
    animation: spin 1000ms infinite linear;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
